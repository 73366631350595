$brand-primary				: #1cbac8;
$light-blue					: #00bcd4;
$text-color					: #333;
$title-color				: #343434;
$headings-color				: #f3f3f3;

$white						: #ffffff;
$light-grey					: #f7f7f7;
$black						: #000;
$black-russian-grey			: #24252a;
$suva-grey					: #888888;


$popins-font				: 'Poppins', sans-serif;
$fontawesome				: 'FontAwesome';


$navbar-link-color			: $black-russian-grey;
$navbar-hover-color			: $brand-primary;
$navbar-hover-bg			: $brand-primary;
$navbar-active-color		: $brand-primary;
$navbar-active-bg			: transparent;

$hover-line-color			: $brand-primary;
$active-line-color			: $hover-line-color;


$dropdown-bg				: rgba(26, 28, 39, 0.8);
$dropdown-wrap-color		: $black;
$dropdown-link-color		: $white;

$dropdown-hover-color		: $white;
$dropdown-hover-bg			: $brand-primary;

$dropdown-active-color		: $brand-primary;
$dropdown-active-bg			: $brand-primary;