/*
Theme Name: Bonding
Description: Bonding is a Personal Portfolio Template. Its a full responsive theme built with Bootstrap.
Version: 1.0
Author: TheShahriyar
Author URI: http://theshahriyar.com
*/

/**==========================================**/
/** Table of Content
/**==========================================**/
/**

** Banner & Slideshow
** Feature
** Timeline Styling
** Portfolio
** Animated Number
** Progress Bar
** Testimonial Style
** Pricing Table
** Latest News
** Video Background
** Contact Page Style
** Copyright
** Back to Top

**/


//------------------------------------
// Imported File
//------------------------------------

@import 'variable';
@import 'global-styles';
@import 'navbar';




//--------------------------------------
// Start Banner Section
//--------------------------------------

.banner {
	padding: 300px 0 200px 0;

	h1 {
		font-size: 70px;
		line-height: 70px;
		font-weight: 700;
		margin-bottom: 40px;

		span {
			color: $brand-primary;
		}
	}

	p {
		margin-bottom: 50px;
	}

	&.white {
		h1,p {
			color: #fff;
		}
	}

	&.primary {
		h1,p {
			color: $brand-primary;
		}
	}
}


.video-bg {
	position: relative;

	h3{
		color:#fff;
	}
	#customElement {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
	}
}


.preview-banner {
	padding: 200px 0;

	h1 {
		font-family: "Kaushan Script","Helvetica Neue",Helvetica,Arial,cursive !important;
		font-size: 70px;
		line-height: 70px;
		font-weight: 700;
		margin-bottom: 40px;
		text-transform: none !important;
		color: $brand-primary !important;
	}

	p {
		margin-bottom: 50px;
		font-size: 22px;
		text-transform: uppercase;
	}

	&.white {
		h1,p {
			color: #fff;
		}
	}

	&.primary {
		h1,p {
			color: $brand-primary;
		}
	}
}



//---------------------------------------
// Start Revolution Slideshow
//---------------------------------------

.rev_slider_wrapper {
	
	.tp-leftarrow.hermes {
		position: absolute;
		left: 0 !important;
	}
	.tp-rightarrow.hermes {
		position: absolute;
		right: 0 !important;
	}

	.btn.btn-primary {
		&:hover {
			color: #fff !important;
		}
	}
}


//------------------------------------
// Start Call to Action Section
//------------------------------------

.call-to-action {
    padding: 50px 0;
    background: $brand-primary;
    text-align: center;

    h2 {
    	font-size: 15px;
	    font-weight: normal;
	    margin: 0 0 15px;
	    text-transform: uppercase;
	    letter-spacing: 2px;
	    color: #ffffff;
	    margin-bottom: 0;

	    a {
	    	display: inline-block;
		    text-decoration: none;
		    transition: all 0.2s ease-in-out;
		    -moz-transition: all 0.2s ease-in-out;
		    -webkit-transition: all 0.2s ease-in-out;
		    -o-transition: all 0.2s ease-in-out;
		    margin-left: 140px;
		    padding: 8px 30px;
	    }
    }
}


.call-to-action-2 {
	
	h3 {
		font-size: 52px;
		text-transform: uppercase;
		margin-bottom: 36px;
		font-weight: 700;
	}

	p {
		padding: 0 10%;
		margin-bottom: 40px;
		font-size: 16px;
	}

	&.white {
		h3,p {
			color: #fff;
		}
	}
}




//-------------------------------------
// Start Feature Style Section
//-------------------------------------

.feature-box {
	background: url('../images/slideshow/about-bg.jpg') center fixed;
	background-size: cover;
	-webkit-background-size: cover;
	position: relative;
	padding-top: 1px;

	.inner-feature-box {
		overflow: hidden;
		margin-top: -51px;
	}

	.feature-post {
		text-align: center;
		float: left;
		width: 25%;
		position: relative;
		padding-bottom: 340px;
		background: transparent;
		margin-top: 50px;
		z-index: 2;
		transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;

		.feature-post-content {
			/*height: 200px;*/
			background: #ffffff;
			padding: 0 30px 60px;
			border-top: 1px solid #ececec;
			border-right: 1px solid #ececec;
			position: relative;
			z-index: 2;
			transition: all 0.2s ease-in-out;
			-webkit-transition: all 0.2s ease-in-out;

			a {
				display: inline-block;
				text-decoration: none;
				transition: all 0.2s ease-in-out;
				-webkit-transition: all 0.2s ease-in-out;
				width: 100px;
				height: 100px;
				margin: -50px 0 24px;
				line-height: 100px;
				color: #dadfe1;
				font-size: 30px;
				text-align: center;
				background: #ffffff;
				border: 1px solid #ececec;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 50%;

				&:hover {
					background: #1cbac8;
					color: #ffffff;
				}
			}

			h2 {
				/*height: 32px;*/
				color: #222222;
				font-size: 15px;
				font-weight: 700;
				margin: 0 0 15px;
				text-transform: uppercase;
				letter-spacing: 1.8px;
				transition: all 0.2s ease-in-out;
				-webkit-transition: all 0.2s ease-in-out;
			}

			p {
				margin: 0 auto;
				max-width: 210px;
				transition: all 0.2s ease-in-out;
				-webkit-transition: all 0.2s ease-in-out;
			}
		}

		.hover-feature {
			position: absolute;
			top: 50%;
			width: 100%;
			left: 0;
			height: 100%;
			padding: 0 30px;
			padding-top: 80px;
			background: transparent;
			text-align: center;
			transition: all 0.2s ease-in-out;
			-webkit-transition: all 0.2s ease-in-out;

			h2 {
				color: #222222;
				font-size: 15px;
				font-weight: 700;
				margin: 0 0 15px;
				font-size: 13px;
				color: #ffffff;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: 1.8px;
				margin-bottom: 15px;
			}

			h1 {
				color: #222222;
				font-size: 24px;
				font-weight: 900;
				margin: 0 0 30px;
				text-transform: uppercase;
				color: #ffffff;
				letter-spacing: 2px;
				margin-bottom: 40px;
			}

			span {
				display: inline-block;
				transition: all 0.2s ease-in-out;
				-webkit-transition: all 0.2s ease-in-out;
				width: 62px;
				height: 5px;
				border-top: 1px solid #ffffff;
				border-bottom: 1px solid #ffffff;
				margin: 0 0 40px;
				opacity: 0;
			}

			p {
				color: #ffffff;
				max-width: 340px;
				margin: 0 auto;
				opacity: 0;
				transition: all 0.2s ease-in-out;
				-webkit-transition: all 0.2s ease-in-out;
			}
		}

		&:hover {
			.feature-post-content {
				background: transparent;

				h2,p {
					opacity: 0;
				}

				a {
					background: #1cbac8;
					border: 1px solid #1cbac8;
					color: #ffffff;
				}
			}

			.hover-feature {
				top: 0;
				padding-top: 100px;

				span, p {
					opacity: 1;
				}
			}


		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #333;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			-webkit-transition: all 0.2s ease-in-out;
		}

		&:hover:before {
			opacity: 0.65;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(51, 51, 51, 0.6);
	}
}




.feature-2 {
	margin-bottom: 30px;

	.feature-icon {

	    i {
    	    font-size: 45px;
		    width: 45px;
		    height: 45px;
		    line-height: 45px;
		    color: $brand-primary;
		    -webkit-transition: all 0.3s;
		    -o-transition: all 0.3s;
		    transition: all 0.3s;

		    &:before {
		    	font-size: 45px;
		    }
	    }
	}

	.media-body {
		padding-left: 10px;

		h3 {
			font-size: 18px;
			line-height: 24px;
		    margin-bottom: 15px;
		    text-transform: uppercase;
		    color: #000;
		    -webkit-transition: all 0.3s;
		    -o-transition: all 0.3s;
		    transition: all 0.3s;
		}
	}

	&.white {
		.media-body {
			color: #fff;
			h3 {
				color: #fff;
			}
		}
	}

	
}



.feature-3 {
	margin-bottom: 30px;
	display: block;

	a {
		display: inline-block;
	    text-decoration: none;
	    float: left;
	    width: 60px;
	    height: 60px;
	    background: $brand-primary;
	    text-align: center;
	    line-height: 60px;
	    font-size: 22px;
	    color: #ffffff;
	}

	h4 {
	    font-size: 17px;
	    font-weight: 700;
	    margin: 0 0 15px;
	    margin-left: 80px;
	    font-weight: 400;
	    margin-bottom: 0;
	    padding-top: 10px;
	}

	span {
	    display: block;
	    margin: 0;
	    font-size: 11px;
	    font-family: georgia;
	    margin-left: 80px;
	    color: #666;
	    font-style: italic;
	}
}




//----------------------------------------
// Start Revson Tab Section
//----------------------------------------

.othelo-tab {

	.nav-tabs {
		>li {
			position: relative;
		    float: inherit;
		    height: 150px;
		    text-align: center;

		    a {
		    	color: $title-color;
				font-size: 14px;
			    line-height: 22px;
			    font-weight: 600;
			    text-transform: uppercase;
			    padding: 0;
			    border: 0;
			    -webkit-transition: 0.3s;
			    -o-transition: 0.3s;
			    transition: 0.3s;

			    i {
					font-size: 28px;
					color: $title-color;
				    display: block;
				    margin-bottom: 20px;
				    -webkit-transition: 0.3s;
				    -o-transition: 0.3s;
				    transition: 0.3s;
			    }

			    &:hover,
			    &:focus {
			    	background-color: transparent;
					border: 0;
			    }
		    }

		    &.active, &:hover {
		    	a, 
			    a:focus, 
			    a:hover {
			    	background-color: transparent;
					border: 0;
					color: $brand-primary;
			    }

			    i {
			    	color: $brand-primary;
			    }

			    &:before {
			    	position: absolute;
				    z-index: 1;
				    bottom: -5px;
				    left: 48%;
				    content: '';
				    -webkit-transform: rotate(-45deg) translate(-50%);
				    transform: rotate(-45deg) translate(-50%);
				    border-width: 15px;
				    border-style: solid;
				    border-color: #fafafa #fafafa transparent transparent;
			    }
		    }
		}
	}

	.tab-content {
		position: relative;

		&:before,
		&:after {
			position: absolute;
		    top: 0;
		    left: -2000px;
		    width: 2000px;
		    height: 100%;
		    content: '';
		    background: #fafafa;
		}
		&:after {
			right: -2000px;
    		left: inherit;
		}

		.tab-pane {
			padding: 50px 0 80px;
    		background-color: #fafafa;
		}

		blockquote {
		    font-size: 17px;
		    margin: 0;
		    padding: 8px 20px;
		    border-left: 3px solid #32c5d2;

		    strong {
		    	font-weight: normal;
	    		color: $brand-primary;
		    }
		}

	}

}





//======================================
// Start Portfolio Section
//======================================

ul.filter {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 60px;

  li {
	display: inline-block;
	margin-right: 10px;

	a {
		color: $brand-primary;
	    background: transparent;
	    font-size: 12px;
	    text-transform: uppercase;
	    border: 1px solid $brand-primary;
	    -webkit-border-radius: 2px;
	    -moz-border-radius: 2px;
	    -o-border-radius: 2px;
	    border-radius: 2px;
	    padding: 8px 30px;

		&.active,
		&:hover {
			background: $brand-primary;
			color: #fff;
		}
	}

  }

}



.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  transition-property: transform, opacity;
}


.portfolio-box {
  overflow: hidden;
}

.portfolio-post {
	overflow: hidden;
	float:left;
	position: relative;
    top: 0px;
    left: 0px;
    visibility: visible;

	img {
		max-width: 100%;
    	float: left;
	    padding: 0;
	    margin: 0;
	    -webkit-transform: scale3d(1, 1, 1);
	    transform: scale3d(1, 1, 1);
	    -webkit-transition: all 400ms ease-in-out;
	    transition: all 400ms ease-in-out;
	    background-color: white;
	}

	.hover-box {
		position: absolute;
		opacity: 0;
		overflow: hidden;
	    transition: opacity 400ms ease-in, margin 250ms ease;
	    -webkit-transition: opacity 400ms ease-in, margin 250ms ease;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    text-align: center;
	    padding: 10px;
	    margin: 0;
	    border: 0;
	    background: rgba(0, 0, 0, 0.5);
	    color: #fff;
	    z-index: 2;

		.inner-hover {
			width: 100%;
			height: 100%;
			display: table;
			

			.middle {
				vertical-align: middle;
				display: table-cell;
			}

			h4 a {
				color: $white;
				margin-top: 20px;
			    display: inline-block;
			    font-size: 16px;
			    line-height: 1.2;
			    letter-spacing: 1px;
			    -webkit-transform: translate3d(0, 15px, 0);
			    transform: translate3d(0, 15px, 0);
			    -webkit-transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 150ms;
			    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 150ms;
			}

			p {
				padding-top: 15px;
				color: $brand-primary;
				-webkit-transform: translate3d(0, 15px, 0);
			    transform: translate3d(0, 15px, 0);
			    -webkit-transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 300ms;
			    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 300ms;
			}

			.portfolio-post-btn a {
				background: $brand-primary;
			    font-size: 12px;
			    letter-spacing: 2px;
			    line-height: 1;
			    vertical-align: middle;
			    cursor: pointer;
			    display: block;
			    max-width: 100px;
			    margin: 0 auto;
			    padding: 12px 25px;
			    text-align: center;
			    text-transform: uppercase;
			    color: #ffffff;
			    border: 0;
			    border-radius: 0;
			    opacity: 0;
			    -webkit-transform: scale3d(0, 0, 0);
			    transform: scale3d(0, 0, 0);
			    -webkit-transition: all 400ms;
			    transition: all 400ms;

				img {
					display: none;
				}
			}
		}
	}

	&:hover{

		img {
			-webkit-transform: scale3d(1.15, 1.15, 1);
			transform: scale3d(1.15, 1.15, 1);
    		
		}

		.hover-box {
			opacity: 1;
			//margin: 12px;

			.inner-hover {
				h4 a{
					-webkit-transform: translate3d(0, 0, 0);
					-moz-transform: translate3d(0, 0, 0);
					-o-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
				}

				p {
					-webkit-transform: translate3d(0, 0, 0);
					-moz-transform: translate3d(0, 0, 0);
					-o-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
				}

				.portfolio-post-btn a {
					opacity: 1;
				    -webkit-transform: scale3d(1, 1, 1);
				    transform: scale3d(1, 1, 1);
				}

			}
		}
	}

}
.portfolio-details {
	background: #f5f5f5;
    padding: 20px;
    font-size: 13px;
    line-height: 13px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;

    h4 {
	    margin: 0 0 10px;
		padding: 0;
		font-size: 16px;
		line-height: 16px;
		color: #000;
		text-transform: uppercase;
    }

    p {
    	color: $brand-primary;
    	-webkit-transition: all 400ms ease;
    	transition: all 400ms ease;
    }

    i {
    	margin-right: 5px;
    }

    &:hover {
    	background: #333;

    	h4 {
    		color: $white;
    	}
    }
}



button.mfp-close,
button.mfp-arrow {
  outline: none;
}


.portfolio-box.col-3 .portfolio-post {
  width: 33.25%;
  //margin: 1.5px;
}

.portfolio-box.col-4 .portfolio-post {
  width: 25%;
}

.portfolio-box.col-2 .portfolio-post {
  width: 49.95%;
}

.portfolio-box.col-3-sidebar .portfolio-post {
  width: 279px;
  margin: 1.5px;
}


.portfolio-carousel  .owl-buttons div {
	width: 30px;
	height: 30px;
	line-height: 26px;
	background: #f26520 !important;
	opacity: 1;
}

.product-showcase {

	margin-bottom: 50px;

	img {
		margin-bottom: 20px;
	}
	
	p {
		text-transform: uppercase;
		color: #333;
		font-weight: bold;
	}
}



//-----------------------------------------
// Start Animated Number Section
//-----------------------------------------
.animated-counter {
	margin-bottom: 30px;
	background: $brand-primary;
	padding: 30px 20px;
	-webkit-transition: all .5s;
    transition: all .5s;

	.animated-number {
	    font-size: 32px;
	    font-weight: 700;
	    line-height: 36px;
	    position: relative;
	    margin: 0;
	    padding: 0;
	    color: #fff;

    	&:after {
    		position: absolute;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    width: 37px;
		    height: 2px;
		    margin: -20px auto;
		    content: '';
		    background: #fff;
    	}
	}

	h4 {
	    font-size: 14px;
	    line-height: 20px;
	    margin: 40px 0 0;
	    padding: 0;
	    color: #fff;
	}

	&:hover, &.active {
		background: #222;

		.animated-number, h4 {
			color: $brand-primary;
		}
		.animated-number:after {
			background: $brand-primary;
		}
	}
}




//---------------------------------------
// Start Skill Section
//---------------------------------------
.skill-section {
	//margin-top: 20px;
	
	.skill {
	    padding-bottom: 15px;
	}
	
	.skill-name {
		font-size: 14px;
		font-weight: 500;
		color: #333;
		text-transform: uppercase;
		font-weight: 400;
	}

	.progress-bar-percentage {
		font-size: 13px;
		font-weight: 500;
		background: $black-russian-grey;
		color: $white;
		padding: 3px 8px;
		margin-top: -26px;
	}

	.progress {
		overflow: visible;
		height: 5px;
		margin-bottom: 10px;
		margin-top: 5px;
		background: #f9f9f9;
		border-radius: 0px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.progress-bar {
		background: $brand-primary;
		float: left;
		height: 100%;
		font-size: 12px;
		color: #ffffff;
		text-align: center;
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-transition: width 0.6s ease;
		transition: width 0.6s ease;
		position:relative;

	}

	.skill-style-2 {
		padding-bottom: 30px;
	}

	.skill-style-2 .progress {
		height: 20px;
	}

	&.white {
		.skill-name {
			color: #fff;
		}
	}


}



//--------------------------------------
// Start Team Member Section
//--------------------------------------

.team-member-3 {
    position: relative;
    padding-bottom: 30px;
	.team-member-img {
	    position: relative;
	    overflow: hidden;
		&:after {
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    padding: 0 20px;
		    content: '';
		    text-align: center;
		    opacity: 0;
		    background-color: rgba(33, 40, 45, .66);
		    transition: all 0.3s;
		}
		img {
			width: 100%;
		}
	}
	.team-info {
	    position: absolute;
	    right: auto;
	    bottom: 30px;
	    left: auto;
	    width: 100%;
	    padding: 20px;
	    opacity: 0;
	    transition: all .3s;
	    text-align: center;
		transform: translateY(0%);
	    .team-name {
	    	font-size: 32px;
	    	display: block;
	    	margin: 0 auto 20px auto;
	    	text-align: center;
	    	text-transform: uppercase;
		    //transition: transform .3s ease .1s;
		    transform: translateY(0%);
		    line-height: 1.2;
	    }
	    .team-designation {
	    	font-size: 22px;
	    	margin: auto;
	    	padding: 10px 0 0;
	    	text-align: center;
	    	text-transform: uppercase;
		    //transition: transform .4s ease .3s;
		    transform: translateY(0%);
	    }
		.btn-wrap {
			margin: 60px auto 0 auto;
			transition: transform .4s ease 0s;
			transform: translateY(0%);
		}
	}
	.icon {
		position: absolute;
		bottom: 50%;
		left: 0;
		width: 100%;
		padding: 10px 20px;
		transition: transform .3s ease 0s;
		transform: scale(0);
		text-align: center;
		opacity: 0;
		> li {
			display: inline-block;
			&:hover {
				color: $brand-primary;
			}
		}
		a {
			font-size: 16px;
			line-height: 35px;
			display: block;
			width: 35px;
			height: 35px;
			margin: 0 5px;
			transition: all .3s;
			color: $white;
		}
	}
	&:hover, &:focus {
		.team-member-img {
			&:after {
				opacity: 1;
			}
		}
		.team-info {
			transform: translateY(-25%);
			color: $white;
			opacity: 1;
			.team-name {
				//transform: translateY(-10px);
			}
			.team-designation {
				//transform: translateY(-20px);
			}
			.btn-wrap {
				transform: translateY(-30px);
			}
		}
		.icon {
			opacity: 1;
		}
	}
}





//---------------------------------------
// Start Testimonial Section
//---------------------------------------
.testimonial-slide {
	
	.carousel-inner {
		min-height: 250px;

		.item {
			text-align: center;
			padding: 0 15%;
			padding-bottom: 40px;

			p {
				font-style: italic;
				margin-bottom: 25px;
			}

			span {
				text-transform: uppercase;
				letter-spacing: 1.5px;
			}

		}

		&.white {
			color: #fff;
		}
	}
	

	.carousel-control {
	    background: none;
	    color: $text-color;
	    font-size: 26px;
	    text-shadow: none;
	    margin-top: 30px;

	    &:hover {
	    	color: $brand-primary;
	    }
	}
	
	.carousel-control.left {
	    left: -60px;
	}
	.carousel-control.right {
	    right: -60px;
	}
	.carousel-indicators {
	    right: 50%;
	    top: auto;
	    bottom: 0px;
	    margin-right: -19px;
	}
	.carousel-indicators li {
	    width: 40px;
	    height: 40px;
	    margin: 5px;
	    cursor: pointer;
	    border: 4px solid #CCC;
	    border-radius: 50px;
	    opacity: 0.4;
	    overflow: hidden;
	    transition: all 0.4s;
	}
	.carousel-indicators .active {
	    width: 65px;
	    height: 65px;
	    border-radius: 100px;
	    border-color: $brand-primary;
	    opacity: 1;
	    overflow: hidden;
	}
}



//-------------------------------------
// Start Pricing Section
//-------------------------------------

.pricing-box {
	overflow: hidden;
	margin-bottom: 30px;
	box-shadow: 0 2px 3px rgba(0,0,0,.15);
	
	.pricing-head {
		text-align: center;
		padding: 50px 0;

		.previous-price {
			text-decoration: line-through
		}

		h4 {
			padding: 10px 0;
			font-size: 26px;
		}

		h5 {
			font-size: 16px;
		}

		&.white {
			span,h4,h5 {
				color: #fff;
			}
		}
	}

	.pricing-body {
		padding: 10px 30px;

		li {
			padding: 10px 0;

			i {
				float: right;
			}
		}
	}

	.pricing-footer {

		a {
		    display: block;
		    padding: 1em;
		    background: #f2f2f2;
		    color: #2b2c2d;
		    text-align: center;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    transition: all .4s ease-in-out;

		    &:hover {
		    	background: $brand-primary;
		    	color: #fff;
		    }
		}
	}
}




//---------------------------------------
// Start Latest News Section
//---------------------------------------


.latest-news{
	margin-bottom: 30px;

	h4 {
		font-size: 15px;
		position: relative;
		line-height: 22px;
		margin-top: 10px;
		margin-bottom: 10px;
		text-transform: uppercase;

		a {
			color: #282828;
			text-decoration: none;
			-webkit-transition: all.5s ;
			-o-transition: all.5s ;
			transition: all.5s ;

			&:hover {
				color: $brand-primary;
				-webkit-transition: all.5s ;
				-o-transition: all.5s ;
				transition: all.5s ;
			}
		}

	}

	.latest-news-img{
		position: relative;

		img {
			width: 100%;
		}

		div {
			position: absolute;
			background: $brand-primary;
			text-align: center;
			left: 20px;
			top: 20px;
			display: inline-block;
			text-decoration: none;
			padding: 7px 10px;
		}

		span:first-child {
			font-size: 12px;
			color: #fdfdfd;
			display: block;
		}

		span:last-child{
			font-size: 26px;
			color: #fdfdfd;
			font-weight: bold;
			margin-top: 0;
			display: block;
			position: relative;

			&:after {
				position: absolute;
				content: "";
				border-color: $brand-primary transparent transparent $brand-primary;
			    border-style: solid;
			    border-width: 5px;
			    bottom: -16px;
			    height: 0;
			    left: -10px;
			    width: 0;
			}
		}
	}

	.comment{
		margin-top: 12px;
		min-height: 30px;

		p {

			&:first-child {
				float: left;
				font-size: 12px;
				color: #474747;

				span {
					color: #b8b8b8;
				}
			}

			&:last-child {
				float: right;
				font-size: 12px;
				color: #474747;
				margin-right: 0;

				span {
					margin-left: 10px;

					a {
						display: inline-block;
						margin-right: 7px;

						i {
							font-size: 15px;
							color: #b8b8b8;
						}
					}
				}
			}
		}
	}
}



//-------------------------------------
// Start Contact Form Section
//-------------------------------------

.form-map {
	position: relative;

	#map {
	    width: 100%;
	    border: 0;
	    position: absolute;
	    z-index: 99;
	}
}
.form-section {
	
	.black {
		background: #282828;
	}

	.full-height {
		z-index: 999;
	    position: relative;
	    padding: 80px 50px;
	}
}

.contact-form {
	
	input[type="text"], 
	input[type="email"], 
	textarea {
		width: 100%;
		height: auto;
		line-height: 2;
		display: block;
		padding: 8px 12px;
		background: transparent;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		-o-border-radius: 0;
		border-radius: 0;
		color: #999999;
		font-size: 13px;
		border: 1px solid #ccc;
		outline: none;
		margin: 0 0 20px;
		transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;

		&:focus {
			border: 1px solid $brand-primary;
		}
	}

	textarea {
		min-height: 160px;
		margin-bottom: 30px;
	}
}






//--------------------------------------
// Start Footer Element Section
//--------------------------------------

.footer-section {
	p {
		color: #bfbfbf;
	}
}

.footer-text {
	margin-bottom: 30px;
	
	p {
		color: #bfbfbf;
	}
}



.footer-office-hour {
	margin-bottom: 30px;

	ul {
		li {
		
			a {
			    font-size: 13px;
			    color: #bfbfbf;
			    text-decoration: none;
			    padding: 2px 0px;
			    display: block;
			    padding-left: 30px;
			}

			&:first-child {
				a {
				    color: $brand-primary;
					padding-left: 5px;
				}
			}
		}

		&:last-child {
			margin-top: 20px;
		}
	}
	
	
}

.client-logo {
	text-align: center;
}


.footer-list {
	margin-bottom: 30px;

	ul {
		li {
			a {
				color: #bfbfbf;
			    font-size: 13px;
			    font-weight: 600;
			    display: block;
			    padding: 5px 0px;
			    text-decoration: none;
			    -webkit-transition: all 0.4s;
			    transition: all 0.4s;

			    i {
			    	padding-right: 8px;
			    }

			    &:hover {
			    	color: $brand-primary;
			    }
			}
		}
	}
}

.footer-subscribe {
	margin-top: 20px;
	margin-bottom: 30px;
	position: relative;

	input,
	input:focus {
	    background: #1c1c1c;
		border-radius: 0px;
		border: 0px solid;
		height: 40px;
		outline: none;
		box-shadow: none;
	}

	button,
	button:focus,
	button:hover {
		position: absolute;
	    right: 0;
	    top: 0;
	    background: $brand-primary;
	    width: 60px;
	    height: 100%;
	    font-size: 13px;
	    color: #fff;
	    font-weight: 600;
	    border: 0px solid;
	    border-radius: 0px;
	    outline: none;
	}
}


.footer-social {
	
	ul.social {

    	li {
    		font-size: 16px;
		    line-height: 16px;
		    display: inline-block;
		    margin: 0 1px 0 0;
		    padding: 5px 10px;
		    list-style: none;
		    text-align: center;
		    background: $brand-primary;
		    border-radius: 2px;
		    -webkit-transition: all 0.3s;
		    -o-transition: all 0.3s;
		    transition: all 0.3s;

		    &:first-child {
		    	margin-left: 0;
		    }

		    a {
	    	    text-decoration: none;
			    color: $white;
			    -webkit-transition: all 0.3s;
			    -o-transition: all 0.3s;
			    transition: all 0.3s;

			    i {
			    	text-align: center;
			    	padding-top: 3px;
			    }
		    }

		    &:hover {
		    	background: $white;

		    	i {
		    		color: $black-russian-grey;
		    	}
		    }
    	}
    	
    }
}

.copyright {
	border-top: 1px solid #353535;
    padding: 30px 0px;
    margin-top: 50px;

    .copyright-text {
    	p {
    		color: #9b9b9b;
    	}
    	a {
    		color: $brand-primary;
    		font-weight: 600;
    	}
    }
}

.footer-minimal {
	h3 {
		font-family: "Kaushan Script","Helvetica Neue",Helvetica,Arial,cursive !important;
		color: #fff;
		text-transform: none;
		margin-bottom: 30px;
	}

	a {
		color: #fff;
	}
}



//-----------------------------
// Back to Top
//-----------------------------

.back-to-top.reveal {
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    opacity: 30;
    filter: alpha(opacity=3000);

    &:focus,
    &:active,
    &:hover {
    	background-color: $black-russian-grey;
	    opacity: 100;
	    filter: alpha(opacity=10000);
    }
}

.back-to-top {
    position: fixed;
    z-index: 1000;
    right: 25px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    margin: 0;
    background-color: $brand-primary;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 350ms;
    -o-transition: opacity 350ms;
    transition: opacity 350ms;

    i {
	    position: absolute;
	    top: 50%;
	    left: 35%;
	    margin-top: -15px;
	    margin-left: -3px;
	    color: #ffffff;
    }
}


