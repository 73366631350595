//Global Styles Start

body {
	font-family: $popins-font;
	color: $text-color;
	font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: .5px;
    overflow-x: hidden;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

#container {
    background: #fff;
    position: relative;
    overflow-x: hidden;
    margin: 0 auto;
}

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.fa-ul {
    margin-left: 22px
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight:600;
    color: $text-color;
    //letter-spacing: 1.5px;
    font-family: $popins-font!important;
}

img {
    max-width: 100%;
    height: auto;
}

.text-left {
	text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
	text-align: center
}

.text-brand-color {
    color: $brand-primary;
}

.img-center {
    margin-left: auto;
    margin-right: auto;
}

.circle {
    -webkit-border-radius: 100%;
    border-radius: 100%;
}


.white {
    color: #fff !important;
}

a {
	-webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
        color: $text-color;
        outline: 0;
    }
}

::-moz-selection {
    background: $brand-primary;
    color: #fff;
    text-shadow: none;
    outline: none;
}

::selection {
    background: $brand-primary;
    color: #fff;
    text-shadow: none;
    outline: none;
}

.main-container {
    overflow: hidden;
}
.dropcap {
    font-size: 38px;
    font-weight: 400;
    line-height: 58px;
    float: left;
    width: 60px;
    height: 60px;
    padding: 0 10px 0 14px;

    &.bg {
        margin-right: 25px;
        margin-bottom: 0;
        color: $white;
        background: $brand-primary;
        text-align: center;
        color: #fff;
    }

    &.circle {
        border-radius: 50%;
    }

    &.rounded {
        border-radius: 5px;
    }
}



hr {
    margin-top: 0;
    margin-bottom: 0;
}
.btn {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.btn-primary {
    color: #ffffff;
    background: transparent;
    font-size: 12px;
    //font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #fff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 48px;

    &:hover {
        background: $brand-primary;
        border-color: $brand-primary;
        color: #fff;
    }

    &.white {
        background: #fff;
        color: $brand-primary !important;
        &:hover {
            background: $black-russian-grey;
        }
    }

    &.white-border {
        border: 1px solid #ffffff;
        &:hover {
            background: #fff;
            color: $brand-primary;
        }
    }

    &.black-border {
        border: 1px solid #222;
        color: #222;

        &:hover {
            background: $brand-primary;
            color: #fff;
            border: 1px solid $brand-primary;
        }
    }

}


.right-half, .left-half {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-position: center center;
    background-size: cover;

    &.width33 {
        width: 33%;
    }
    &.width50 {
        width: 50%;
    }
}

.left-half {
    left: 0;
}



// Navbar Fixed Top

.navbar-fixed-top {
    position: fixed !important;
    right: 0;
    left: 0;
    z-index: 1100;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    animation-duration: .8s;
    -webkit-animation-duration: .8s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}




//margins start
.mt60 {
    margin-top: 60px;
}
.mt65 {
    margin-top: 65px;
}
.mt30 {
    margin-top: 30px;
}
.mt25 {
    margin-top: 25px;
}
.mb15 {
    margin-bottom: 15px;
}
.mb30 {
    margin-bottom: 30px;
}
.mb40 {
    margin-bottom: 40px;
}
.mb60 {
    margin-bottom: 60px;
}
.mr10 {
    margin-right: 10px;
}
//margins end
//paddings start
.pad15 {
    padding: 15px 0;
}
.pad30 {
    padding: 30px 0;
}
.pad50 {
    padding: 50px 0;
}
.pad60 {
    padding: 60px 0;
}
.pad80 {
    padding: 80px 0;
}
.pad100 {
    padding: 100px 0;
}
.pad120 {
    padding: 120px 0;
}

.pad-t100 {
    padding-top: 100px;
}
.pad-t80 {
    padding-top: 80px;
}
.pad-t30 {
    padding-top: 30px;
}
.pad-t50 {
    padding-top: 50px;
}
.pad-t60 {
    padding-top: 60px;
}
.pad-t90 {
    padding-top: 90px;
}
.pad-t120 {
    padding-top: 120px;
}
.pad-b30 {
    padding-bottom: 30px;
}
.pad-b40 {
    padding-bottom: 40px;
}
.pad-b50 {
    padding-bottom: 50px;
}
.pad-b60 {
    padding-bottom: 60px;
}
.pad-b70 {
    padding-bottom: 70px;
}
.pad-b80 {
    padding-bottom: 80px;
}
.pad-b100 {
    padding-bottom: 100px;
}
//paddings start



.section-title{
    position: relative;
    margin: 0 0 80px;
    
    h3 {
        position: relative;
        font-size: 35px;
        line-height: 42px;
        color: $text-color;
        font-family: "Kaushan Script","Helvetica Neue",Helvetica,Arial,cursive !important;
        text-transform: none;

        span {
            color: $brand-primary !important
        }

        &:after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 37px;
            height: 1px;
            margin: -20px auto;
            content: '';
            opacity: 1;
            background: $text-color;
        }
    }

    p {
        max-width: 840px;
        margin: 40px auto 0;
    }
    
    &.white {
        h3 {
            color: $white;

            &:after {
                background: #fff !important;
            }
        }
    }

    &.left {
        h3 {
            &:after {
                left: 0;
                right: 100%;
            }
        }
    }

    &.small {
        margin: 0 0 40px;

        h3 {
            font-size: 28px;
        }
    }

}

.footer-title {
    
    h3 {
        font-size: 17px;
        text-transform: uppercase;
        color: #ffffff;
        position: relative;
        margin-bottom: 20px;
    }
}



blockquote {
    padding: 12.5px 25px;
    margin: 0 0 25px;
    //font-size: 17.5px;
    font-style: italic;
    border-left: 3px solid $black-russian-grey;

    p {
        //font-size: 17.5px;
    }

    &.primary {
        border-left-color: $brand-primary;
    }

    &.ash-bg {
        background-color: #f7f7f7;
    }
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #999;

    &.dotted {
        border-top: 1px dotted #999;
    }

    &.dashed {
        border-top: 1px dashed #999;
    }
}


.parallax {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 0;
    background-size: cover;
}

// flaticon override start
[class^="flaticon-"]:before, 
[class*=" flaticon-"]:before, 
[class^="flaticon-"]:after, 
[class*=" flaticon-"]:after {
    margin-left: 0;
}
//flaticon override end

//FontAwesome override start
.fa-ul {
    li {
        padding: 5px 0;
        .fa-li {
            margin-top: 8px;
            color: $brand-primary;
        }
    }
}
//FontAwesome override end


.shadow {
    box-shadow: -25px 0 30px -15px rgba(0, 0, 0, 0.15), 25px 0 30px -15px rgba(0, 0, 0, 0.15);
}


//Global Styles End